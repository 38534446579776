import React from 'react';
import FoxNotFound from "../../resources/fox_page_not_found.png"
import ctrans from "../../common/trans"
import { css, Header, Div, Button } from "@bdfx/fukit"
import {clip, shadow} from "../homepage/section"
import history from "../../common/history"
import {eventGA, plausible} from "../../common/tracking"

class ErrorPage extends React.PureComponent<{}, {}> {

    componentDidMount() {
        setTimeout(() => {
            eventGA("PAGE", "Error page 404", "ERROR_404")
            plausible("404",{ props: { path: document.location.pathname } })
        
        }, 2000)
    }

    handleGoBack = () => history.push('/')

    render() { 
        return (
            <Style>
                <div>
                    <Div textAlign="right" paddingTop="150px" minHeight={"350px"}>
                        <Header type={"h1"}>{ctrans("page_not_found")}</Header>
                        <img src={FoxNotFound} alt={ctrans("page_not_found")} />
                        <p>
                            {ctrans("page_not_found_info")}
                        </p>
                        <Button color="green" size="tiny" icon="plus-light" secondary={true} onClick={this.handleGoBack}>
                            {ctrans("create_new_audit")}
                        </Button>
                    </Div>
                </div>
            </Style>
        )
    }
}

const Style = css("div")({
    filter: shadow,
    position: "relative",
    textAlign: "left",
    "> div": {
      clipPath: clip,
      background: "#fff",
      marginTop: "-300px",
      paddingTop: "350px",
      paddingBottom: "120px",
      "> div": {
        maxWidth: "1200px",
        margin: "auto",
        },
    },
    "img": {
        float: "right",
        marginTop: "-50px",
    },
    "p": {
        textAlign: "right",
        fontSize: "1.5rem",
    }
})

export default ErrorPage